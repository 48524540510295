<template>
  <div>
    <!-- header -->
    <div class="head_box">
      <div class="header d-flex main">
        <div class="header_left">
          <div class="hed_log">
            <img class="log_im" src="@/assets/image/logo.png" alt="" />
          </div>
        </div>
        <div class="d-none d-md-block">
          <div class="header_right d-flex">
            <div
              class="menu_box ft14"
              :class="{ addcol: activeIndex == i }"
              v-for="(el, i) in topmenu"
              :key="i"
              @click="activeHandle(i)"
            >
              <div>{{ el }}</div>
            </div>
          </div>
        </div>
        <!-- 弹框 -->
        <div class="d-md-none">
          <div class="dropdown">
            <span
              class="el-icon-s-unfold"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
            ></span>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              <li class="dropdown_li" v-for="(el, i) in topmenu" :key="i">
                <a class="dropdown-item" href="#">{{ el }}</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <router-view></router-view>
    <!-- footer -->
    <div class="footer_box">
      <div class="w1440">
        <div class="cent-box d-flex">
          <dl class="footer_leftBox">
            <dt class="ft20">联系我们 ———</dt>
            <dd class="minft">
              <p>
                <!-- <img
                  style="width: 20px; height: 20px"
                  src="@/assets/image/index_41.png"
                /> -->
                电话：(+86)-0731-85582181
              </p>
              <p>
                <!-- <img
                  style="width: 20px; height: 20px"
                  src="@/assets/image/index_42.png"
                /> -->
                邮件:support@feeshine.com
              </p>
              <p>
                <!-- <img
                  style="width: 20px; height: 20px"
                  src="@/assets/image/index_43.png"
                /> -->
                地址:长沙市高新技术开发区麓谷企业广场F1栋24楼
              </p>
            </dd>
          </dl>
          <dl class="footer_rightBox d-none d-md-block">
            <dt class="ft20">业务联系方式</dt>
            <dd class="footer_minBox d-flex ft16">
              <div class="box_min">
                <p class="lin_ft">北京业务负责人：武经理</p>
                <p class="lin_ft">邮件:wuwenyang@feeshine.com</p>
                <p class="lin_ft">手机:152 0142 5227</p>
              </div>
              <div class="box_min">
                <p class="lin_ft">上海业务负责人：王经理</p>
                <p class="lin_ft">邮件wanggengxi@feeshine.com</p>
                <p class="lin_ft">手机:138 0846 2937</p>
              </div>
              <div class="box_min">
                <p class="lin_ft">深圳业务负责人：陈经理</p>
                <p class="lin_ft">邮件:chenshenyu@feeshine.com</p>
                <p class="lin_ft">手机:159 1417 3365</p>
              </div>
            </dd>
          </dl>
        </div>
        <div class="links">
          <span>友情链接：</span>
          <a href="" target="_blank">结果交付创新者</a>
          <a href="" target="_blank">自由工作领航者</a>
        </div>
        <div class="copyright">
          © 2017-2021 湖南微瞰智能科技有限公司 版权所有 |
          <a href="https://beian.miit.gov.cn/" target="_blank"
            >湘ICP备2021006111号-
            <span>2</span>
          </a>
        </div>
        <div class="clear"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      topmenu: ["首页", "产品线", "应用软件", "行业方案", "联系我们"],
      activeIndex: 0,
      userName: ""
    }
  },
  methods: {
    activeHandle(index) {}
  }
}
</script>

<style lang="scss" scoped>
// header
.head_box {
  width: 100%;
  height: 80px;
  background: #4066F5;
  // background: #4066F5;
  padding-left: 20px;
}
.header {
  // height: 100%;
  justify-content: space-between;
  align-items: center;
  // background: #4066F5;
  background: #FFE501;
}
.hed_log {
  display: flex;
  width: 260px;
  height: 36px;
}
.log_tii {
  margin-top: 10px;
  margin-right: 4px;
  color: #d6c005;
}
.log_tii1 {
  margin-top: 14px;
  font-weight: 600;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
}
.log_im {
  height: 100%;
}
.el-menu-demo {
  height: 100%;
}
.login_box {
  align-items: center;
  margin: 0 14px;
}
.btn_login {
  width: 80px;
  height: 34px;
  line-height: 34px;
  border: 1px solid #1b1b1b;
  border-radius: 17px;
  box-sizing: border-box;
  text-align: center;
}
.header_right {
  height: 100%;
}

.menu_box {
  margin: 0 14px;
  padding: 0 16px;
  padding-top: 14px;
  display: flex;
  // align-items: center;
  justify-content: space-evenly;
  // border-bottom: 4px solid #ffe501;
}
.addcol {
  box-sizing: border-box;
  padding-top: 14px;
  padding-bottom: 10px;
  border-bottom: 3px solid #fff;
}
// 弹框
.el-icon-s-unfold {
  font-size: 28px;
  margin-right: 6px;
}
.dropdown_li {
  border-bottom: 1px solid #ccc;
  padding: 10px;
}
// footer
.footer_box {
  height: 310px;
  background: #FFE501;
  // background: #4066F5;
  padding: 50px 10px;
}
.cent-box {
  justify-content: space-between;
}
.copyright {
  text-align: center;
}
dd {
  margin-top: 10px;
}
.box_min {
  margin-right: 12px;
  flex-direction: column;
  flex-wrap: wrap;
}
.lin_ft {
  margin-bottom: 16px;
}
@media only screen and (min-width: 760px) and (max-width: 1169px) {
  .box_min {
    font-size: 12px;
  }
  .minft {
    font-size: 12px;
  }
}
// 手机端
.dropdown-menu {
  width: calc(100vw);
  height: calc(100vh);
  // background: #4066F5;
  background: #FFE501;
}
//固定
.fixed_box {
  position: fixed;
  left: 10px;
  bottom: 2%;
  width: 160px;
  height: 180px;
  background: #fff;
  text-align: center;
  font-size: 14px;
  color: #333;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.5);
  border-radius: 6px;
}
.fixed_imbox {
  width: 140px;
  margin: 0 auto;
}
.fixed_p1 {
  font-weight: bold;
}
.fixed_p2 {
  font-size: 12px;
  color: #000;
  color: rgb(100, 141, 245);
  font-weight: bold;
}
</style>
